import inesemMaster from "./inesem-master.pdf";
import euresSuplement from "./eures-suplement.pdf";
import isdiCodersBootcamp from "./syllabus-isdi-coders.pdf";
import angularUdemy from "./angular-udemy.pdf";
import scrumMaster from "./scrum-master.pdf";

export const downloads = {
  inesemMaster,
  euresSuplement,
  isdiCodersBootcamp,
  angularUdemy,
  scrumMaster,
};
