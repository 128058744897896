import { SiProgress } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { AiFillAppstore } from "react-icons/ai";
import { downloads } from "../assets/downloads/downloads";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <SiProgress />,
    title: "Frontend Development",
    des: "My projects stand out with a modern tech stack featuring React, JavaScript, Typescript, HTML5, CSS3, Styled-Components, and testing using Jest and React Testing Library. I'm passionate about crafting efficient and stylish web solutions.",
  },
  {
    id: 2,
    icon: <CgWebsite />,
    title: "Backend Development",
    des: "I'm building my expertise in backend application development, with a foundation in NodeJS, MongoDB, and initial experience in microservices architecture with Java and Springboot. Ready to grow and excel.",
  },
  {
    id: 3,
    icon: <AiFillAppstore />,
    title: "App Development",
    des: "Dedicated to mastering React Native, I'm deeply committed to honing my skills for crafting versatile cross-platform applications. With a relentless focus on mobile development, I prioritize continuous learning and advancement in the field.",
  },
];

export const skills = {
  markupAndLanguages: ["html", "css", "javascript", "typescript", "java"],
  frontendTechnologies: [
    "react",
    "redux",
    "angular",
    "vite",
    "chackra-ui",
    "styledcomponents",
    // "nextjs",
    // "sass",
    // "webpack",
  ],
  backendAndDatabases: ["nodejs", "mongodb" /*"spring", "mysql"*/],
  toolsAndOther: ["git", "figma", "rally", "jenkins"],
};

export const techExperience = [
  {
    title: "Frontend Developer",
    subTitle: "Viewnext (IBM Spain), Remote, Spain. 02/2024 - Present",
    description:
      "Develop microfrontend applications for private banking services, reducing manual account management by 40% through automation.\n- Make important decisions about app structure and work with UX designers to create seamless user experiences.\n- Reduce production bugs by 25% through early-stage unit and integration testing with Jest and React Testing Library.\n- Handle state with Context API and Redux, optimizing performance and scalability.\n- Take part in the Scrum team’s processes, helping improve workflows and supporting team members with technical issues.",
    link: undefined,
  },
  {
    title: "Frontend Developer",
    subTitle: "Rodomnia UG, Remote, Germany. 01/2022 - 01/2024",
    description:
      "Develop a personnel management system, focusing on building reliable front-end applications using ReactJS and Next.js.\n- Optimize app performance through lazy loading and efficient state management.\n- Make key decisions about the app architecture and use TypeScript to prevent bugs.\n- Work with an agile approach and follow SCRUM methodology as part of a remote team.\n- Communicate effectively with international team members, ensuring smooth project execution.",
    link: undefined,
  },
];

export const othersExperience = [
  {
    title: "Field Service Engineer",
    subTitle: "Barberan S.A. Barcelona, Spain. 03/2020 - 01/2022",
    description:
      "Specialized in commissioning and maintaining automatic industrial machinery and electrical systems.\n- Programmed PLCs and automated industrial processes, improving production efficiency.\n- Managed complex electrical systems, troubleshot issues, and assembled electrical components and panels.",
    link: undefined,
  },
];

export const jobExperience = [...techExperience, ...othersExperience];

export const educationDes = [
  {
    title: "Advanced Expert in Electrotechnical Systems",
    school: "IES Alhamilla, Almería (2008 - 2010)",
    description:
      "Developed and supervised the implementation of electrical distribution systems across medium and low voltage networks, including transformation centers. Designed specific electrical and automation solutions for domestic, commercial, and industrial applications, ensuring compliance with regulations.",
    link: downloads.euresSuplement,
  },
  {
    title: "Master in Automatic Systems and Electronics Engineering",
    school: "INESEM Business School, Granada. (2017 - 2018)",
    description:
      "Specialized in PLC software development under the standard IEC 61131-3, utilizing Siemens TIA-Portal for developing automatic systems. Conducted fieldbus analysis to determine the most suitable options for specific applications.",
    link: downloads.inesemMaster,
  },
];

export const certificates = [
  {
    title: "Master in Full Stack Web Development",
    school: "ISDI Coders, Barcelona. (2022)",
    description:
      "Developed web applications using React, with a focus on creating seamless user experiences. Utilized Redux for state management, built RESTful APIs, and ensured 100% testing code coverage with Jest and Cypress.",
    link: downloads.isdiCodersBootcamp,
  },
  {
    title: "Professional Scrum Master™ I (PSM I)",
    school: "Scrum.org, Online. (2024)",
    description:
      "Certified knowledge in agile project management, focusing on Scrum principles, team collaboration, and iterative development processes.",
    link: downloads.scrumMaster,
  },
  {
    title: "Complete Angular Course",
    school: "Udemy, Online. (2024)",
    description:
      "Fundamentals of Angular framework, covering component architecture, reactive programming with RxJS, and state management.",
    link: downloads.angularUdemy,
  },
];
