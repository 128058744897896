import React from "react";
import Media from "./Media";
import { bannerText } from "../../data/bannerData";

const LeftBanner = () => {
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-6xl font-bold text-gray-400">
          Hi, I'm <span className="text-headers capitalize">Iván García</span>
        </h1>
        <h2 className="text-4xl font-bold text-gray-400">
          a Full Stack Developer.
        </h2>
        <p className="text-content font-bodyFont leading-6 tracking-wide">
          {bannerText}
        </p>
        <div>
          <h2 className="uppercase font-titleFont mb-4 text-title font-bold">
            Find me on
          </h2>
          <Media></Media>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
